import React, { useEffect, useRef, useState } from "react";
import PageWrapper from "../../../components/PageWrapper";
import {
  initWebrtc,
  turnOffCamera,
  turnOffMic,
  turnOffScreen,
  turnOnCamera,
  turnOnMic,
  turnOnScreen,
  closeConnection,
  publishAndPlay,
  cameraSwap,
  cameraSwapMobile,
  shareScreenAdjust,
  checkPermissions,
  getStats,
  disableStats
} from "./AntMediaHelpers";
import "./AntMedia.css";
import { deleteKey, getKey, setKey } from "../../../utils/localStorage";
import AntMediaChat from "./AntMediaChat";
import AntMediaVideo from "./AntMediaVideo";
import AntMediaWaiting from "./AntMediaWaiting";
import {
  extractMsgeUrl,
  isIOS,
  isMessageWithUrl,
  sendAdwordsEvent,
  sendEvent,
  inIframe,
  isMobileAgent,
} from "../../../utils/helpers";
import t from "../../../utils/translations";
import {
  allowIframe,
  cancelIssue,
  checkStatus,
  finishIssue,
  maxWait,
  startIssue,
  createIssueOffline,
  issueDenied,
  connectionStatuts,
  issueEvent,
  finishIssueClient,
} from "../../../services/api";
import { landingOnLoad } from "../../../utils/landingLoad";
import AntMediaAlert from "./AntMediaAlert";
import {
  chatListener,
  database,
  getChat,
  getDataByFirebase,
  getFile,
  getNotification,
  notificationListener,
  fileListener,
  sendSignalClient,
  getSignalCLient,
  sendStatusClient,
  clientFinishCall
} from "../../../utils/firebase";
import AntMediaScreen from "./AntMediaScreen";
import AntMediaRemoteVideo from "./AntMediaRemoteVideo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AntMediaIframe from "./AntMediaIFrame";
import AntMediaPermissions, { alertContent } from "./AntMediaPermissions";
import AntMediaNewButtons from "./AntMediaNewButtons";
import AntMediaFiles from "./AntMediaFiles";
import { sendPixelTrack } from "../../../utils/analytics";
import moment from "moment/moment";

const AntMediaCall = ({ location, history, match }) => {



  /* SOURCES */
  const [cameraOn, setCameraOn] = useState(false);
  const [screenOn, setScreenOn] = useState(false);
  const [micOn, setMicOn] = useState(true);
  const [videoMinimized, setVideoMinimized] = useState(true);
  const [focusOn, setFocusOn] = useState(true);
  const [videSourceIndex, setVideoSourceIndex] = useState(null); // Device id
  const [linkUrl, setLinkUrl] = useState(false);

  /* SHOWERS */
  const [showLocalVideo, setShowLocalVideo] = useState(false);
  const [showRemoteVideo, setShowRemoteVideo] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [showScreen, setShowScreen] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showWaiting, setShowWaiting] = useState(false);
  const [callAlert, setAlert] = useState(null);
  const [notPermissions, setNotPermissionsAlert] = useState(false);
  const [permits, setPermits] =useState(false)
  const [showIframe, setShowIframe] = useState(false);
  const [showHeaderAndFooter, setShowHeaderAndFooter] = useState(true);
  const [showFiles, setShowFiles] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [errorConnection, setErrorConnection] =useState (false)
  const [errorConnectionOperator, setErrorConnectionOperator] =useState (false)
  const [screenAdjust, setScreenAdjust] =useState(false)
  const [roundTripTime, setRoundTripTime] =useState(null)

  /* ANTMEDIA */
  const [webRTCAdaptor, setRTCAdaptor] = useState(null);
  const clientStream = () => `${getKey("issueId")}-customer`;
  const operatorStream = () => `${getKey("issueId")}-operator`;
  const [publish, setPublish]=useState(false)


  /* BUSSINNES */
  const [localState] = useState(location.state);
  const [error, setError] = useState(null);
  const [messageStoreClosed, setMessageStoreClosed] =useState("")
  const [allChats, setAllChats] = useState([]);
  const [localGuid, setLocalGuid] = useState(null);
  const [allFiles, setAllFiles] = useState([]);

  const [pressed, setPressed] = useState(false);
  const [rotate, setRotate] = useState(false);

  /* TIMERS */
  const checkFocusInterval = useRef();
  const checkCallStatusInterval = useRef();
  const checkStillThereTimeout = useRef();
  const checkRttInterval =useRef()
  const checkSendSignalClient= useRef()

  const trackInfo = {
    value: 1,
    currency: "COP",
    content_name: "permitir-camara-y-microfono",
  };

 

  const onPermissionsGranted = () => {
   try {
    setPermits(true)
    initWebrtc(location.state.submit.antMediaWS, onInvalidStream, onPlay,  onDataChannelClose).then(
      (wrtc) => {
        setRTCAdaptor(wrtc);
        sendEvent("Click camara y micrófono");
        sendPixelTrack("segurosmundial", "InitiateCheckout", trackInfo);
        setTimeout(() => {
          publishVideo(wrtc);
          setKey("reload")
     /*      checkMaxQueue(wrtc);
          chatListener(getKey("issueId"), () => chatsFromFirebase());
          fileListener(getKey("issueId"), () => filesFromFirebase());
          notificationListener(getKey("issueId"), () =>
          notificacionsFromFirebase()
          ); */
          /* if(isIOS()) turnOffCamera(webRTCAdaptor, setCameraOn) */
        }, 2000);
      }
    ).catch(error => {
      setErrorConnection(true)
      console.log(error)
    });
   } catch (e) {
    setErrorConnection(true)
    console.log(e)
   }

  }

  useEffect(()=>{
    if(publish){
      setTimeout(()=>{ 
      checkMaxQueue(webRTCAdaptor);
      chatListener(getKey("issueId"), () => chatsFromFirebase());
      fileListener(getKey("issueId"), () => filesFromFirebase());
      /* if(isIOS()) turnOffCamera(webRTCAdaptor, setCameraOn) */
      notificationListener(getKey("issueId"), () =>
      notificacionsFromFirebase()
      );}, 3000)
     
    }
  }, [publish])


  const onDataChannelClose = ()=>{
    closeConnection(webRTCAdaptor, clientStream())
    cancelIssue({ Guid: getKey("issueGuid") })
    clearInterval(checkCallStatusInterval.current);
    clearInterval(checkSendSignalClient.current)
    clearInterval(checkFocusInterval.current);
    clearTimeout(checkStillThereTimeout.current);
    setErrorConnection(true)
  }

  const onPermissionesDenied = () => {
    setNotPermissionsAlert("notPermissions");
    issueDenied({ Guid: getKey("issueGuid") });
  };

  const notpermits = () => {
    setTimeout(() => {
      history.push(getKey("path"));
    },1500);
  };

  const errorConectWebrtc = ()=>{
    setTimeout(() => {
      history.push(getKey("path"));
    },1500);
  }

  useEffect(() => {
    if (!localState) history.push(getKey("path"));
    else {
      setTimeout(() => {
        localState.config.IsOpen &&
          checkPermissions(onPermissionsGranted, onPermissionesDenied);
      }, 5000);
    }
  }, []);

  /* CHECK FOR BACKING */
 useEffect(() => {
   /*  window.history.pushState({ page_id: 1 }, getKey("path")); */
    window.onbeforeunload = e =>askWhenLeaving(e); 
    return(()=>{
      window.onbeforeunload = null
    })
  }, [permits]); 

  useEffect(() => {
    window.history.pushState({ page_id: 1 }, getKey("path"));
    goBackChecker();
  }, [pressed]);

  /* CHECK FOR ERRORS OR CLOSED STORE */
  useEffect(() => {
    landingOnLoad(localState, history, checkForClosedStore);
    setWaitingTimer();
  }, [localState, history]);

  /* BUSSINESS LOGIC */

  const goBackChecker = () => {
    window.addEventListener("popstate", function handleBack(e) {
      let state = e.state;
      if (state !== null) {
        const confirm = window.confirm(t("backWindowNew"));
        if (confirm) {
          setKey("reload");
          showRemoteVideo &&  clientSignalFromFirebase()
          closeConnection(webRTCAdaptor, clientStream());
          const Guid = localGuid || getKey("issueGuid");
          if(!showRemoteVideo){
            cancelIssue({ Guid });
          }
          setTimeout(() => {
            history.push(getKey("path"));
          },1000);
        } else {
          setPressed(!pressed);
          window.removeEventListener("popstate", handleBack);
        }
      }
    });
  };

  const askWhenLeaving = () => {
    const Guid = localGuid || getKey("issueGuid");
    if (!showRemoteVideo && !permits) {
      setKey("reload");
      alert(t("closeWindow"))
        .then(() => history.push(getKey("path")));
    }
    if (!showRemoteVideo && permits) {
      setKey("reload");
      cancelIssue({ Guid })
        .then((response) => {
          if (response.success) alert(t("closeWindow"));
          else alert(t("closeWindow"));
        })
        .then(() => history.push(getKey("path")));
    } /* else{
      finishIssue({ Guid })
      .then((response) => {
        setKey("reload");
        if (response.success) alert(t("closeWindow"));
        else alert(t("closeWindow"));
      })
      .then(() => history.push(getKey("path")));
    } */
     
    return "¿Seguro que quieres salir?";
  };


  const checkForClosedStore = () =>{
    localState.config && !localState.config.IsOpen && setError("closedStore");
    localState.data && localState.data.OfflineBody && setMessageStoreClosed(localState.data.OfflineBody)
  }

  const setWaitingTimer = () =>
    (checkStillThereTimeout.current = setTimeout(
      () => checkForStillThereResponse(),
      420000
    ));

  const checkForStillThereResponse = () => {
    setAlert("stillThere");
    setTimeout(() => callAlert === "stillThere" && cancelAndExit(), 60000);
  };

  const cancelAndExit = () => {
    cancelTicket();
    disableStats(webRTCAdaptor, clientStream())
    closeConnection(webRTCAdaptor, clientStream());
  };

  const onAcceptStillThere = () => {
    setAlert(null);
    setTimeout(() => (!showWaiting ? cancelTicket : finishTicket), 390000);
  };

  const pushNextLandingPage = () => {
    clearInterval(checkCallStatusInterval.current);
    clearInterval(checkSendSignalClient.current)
    clientSignalFromFirebase()
    const { routes } = localState;
    const nextRoute = routes.shift();
    /* const Guid = getKey("guid"); */
    const Guid = localGuid || getKey("issueGuid");
    if (nextRoute === "externalURL")
      window.open(localState.data.ThanksRedirection, "_blank");
    else
      history.push({
        pathname: nextRoute,
        state: { ...localState, config: { ...localState.config, guid: Guid } },
      });
  };

  const finishTicket = () => {
    sendEvent("fin_videollamada", "fin_videollamada");
    deleteKey("ticketCreated");
    const Guid = getKey("issueGuid");
    clientSignalFromFirebase()
    clientFinishCall(getKey("issueId"), moment().valueOf())
    finishIssueClient({ Guid })
    pushNextLandingPage();
    micOff();
    camOff();
    disableStats(webRTCAdaptor, clientStream());
    closeConnection(webRTCAdaptor, clientStream());
    setTimeout(()=>{
      finishIssue({ Guid });
    }, 1000)
    clearInterval(checkCallStatusInterval.current);
    clearInterval(checkFocusInterval.current);
    clearInterval(checkSendSignalClient.current)
  };

  const cancelTicket = () => {
    clearInterval(checkCallStatusInterval.current);
    sendEvent("fin_videollamada", "fin_videollamada");
    micOff();
    camOff();
    closeConnection(webRTCAdaptor, clientStream());
    cancelIssue({ Guid: getKey("issueGuid") }).then(() =>
      history.push({ pathname: `/${match.params.query}` })
    );
    window.onunload = window.onbeforeunload = null;
  };

  const checkMaxQueue = (webRtc) => {
    const { IdLanding, IdStore, IdDepartment } = localState.submit;
    maxWait({ IdLanding, IdStore, IdDepartment })
      .then(() => initIssue(webRtc))
      .catch(() => setError("queueExceeded"));
  };

  const sendMouseFlowData = (idIssue) =>
    window._mfq &&
    window._mfq.push(["setVariable", "id_issue", toString(idIssue)]);

  const initIssue = (webRtc) => {
    const submitData = localState.submit;
    submitData.Customer = getKey("Guid");
    startIssue({ IdIssue: submitData.IdIssue })
      .then(({ response }) => onSuccessIssueInit(response, webRtc))
      .catch((err) => {
        setError("issueError");
        console.log(err);
      });
  };

  const onSuccessIssueInit = (data, webRtc) => {
    setKey("ticketCreated", true);
    sendMouseFlowData(data.IdIssue);
    sendEvent("Ticket_Vivet", "Contact");
    sendAdwordsEvent(localState.data.PG, localState.data.ConversionCode);
    startFocusCheck(data);
    startCallStatusCheck(data);
    /* publishVideo(webRtc); */
  };


  const addDepartmentId = (submitData) => {
    if (localState.submit["IdDepartment"]) {
      submitData.IdDepartment = localState.submit.IdDepartment;
    }
  };
  const generateOfflineIssue = () => {
    const { routes } = localState;
    const nextRoute = routes.shift();
    const submitData = localState.submit;
    submitData.Customer = getKey("Guid");
    addDepartmentId(submitData);
    createIssueOffline({ IdIssue: submitData.IdIssue }).then(
      ({ response, success }) => {
        if (success)
          history.push({
            pathname: nextRoute,
            state: {
              ...localState,
              config: { ...localState.config, guid: response.Guid },
            },
          });
      }
    );
  };

  const startFocusCheck = (data) =>
    (checkFocusInterval.current = setInterval(
      () => checkClientFocus(data.Guid),
      3000
    ));

  const startCallStatusCheck = (data) =>
    (checkCallStatusInterval.current = setInterval(
      () => checkCallStatus(data.Guid),
      5000
    ));

    const sendDataSignal = () =>
    (checkSendSignalClient.current = setInterval(
      () => clientSignalFromFirebase(),
      15000
    ));

    const startCheckRtt = (data) =>
    (checkRttInterval.current = setInterval(
      () =>{
      setRoundTripTime(getKey("RTT"))
      }, 5000
    ));

  
  const checkClientFocus = (guid) => {
    if (!document.hasFocus()) {
      onMinimize(guid);}
    else{
      onMaximize(guid);
     

    } 
  };

  
  const onMaximize = async (guid) => {
    setFocusOn(true);
    const unfocused = await getDataByFirebase("issues", guid);
    if (unfocused?.minimizado === true){
      issueEvent({Guid:guid, Event:"WINDOW",Value: 0  })
      database
        .collection("issues")
        .doc(guid)
        .set({ minimizado: false });
        sendStatusClient(getKey("issueId"), { "status": "maximizado", "created":moment().valueOf(), "date":moment().format('MMMM Do YYYY, h:mm:ss a')})
    }
  };

  const onMinimize = async (guid) => {
    setFocusOn(false);
    const unfocused = await getDataByFirebase("issues", guid);
    if (!unfocused){
      issueEvent({Guid:guid, Event:"WINDOW",Value: 1  })
      sendStatusClient(getKey("issueId"), { "status": "minimizado", "created":moment().valueOf(), "date":moment().format('MMMM Do YYYY, h:mm:ss a')})
      database
        .collection("issues")
        .doc(guid)
        .set({ minimizado: true });
    }
    if (unfocused?.minimizado === false) {
      issueEvent({Guid:guid, Event:"WINDOW",Value: 1  })
      sendStatusClient(getKey("issueId"), { "status": "minimizado", "created":moment().valueOf(), "date":moment().format('MMMM Do YYYY, h:mm:ss a')})
      database
      .collection("issues")
      .doc(guid)
      .set({ minimizado: true });
    }
  };

  const checkCallStatus = (Guid) =>
    checkStatus({ Guid }).then((response) => {
      const status = response.IdStatus;
      if (status !== 1 && status !== 3 && status !== 4 && status !== 8 && status !== 19 && status !== 20) {
        /* forceConnection(); */
        closeConnection(webRTCAdaptor, clientStream());
        sendEvent("fin_videollamada", "fin_videollamada");
        pushNextLandingPage();
      }
      if (status === 19) {setErrorConnectionOperator(true)}
     
    });

  const openLink = (link) => {
    const linkE3 = link.includes("e3stores");
    allowIframe({ url: link }).then(({ success }) => {
      if (inIframe() || linkE3) window.open(link);
      else {
        if (success) {
          setLinkUrl(link);
          setShowIframe(true);
        } else window.open(link);
      }
    });
  };

  const closeIframe = () => {
    setLinkUrl(null);
    setShowIframe(false);
  };

  const chatsFromFirebase = () =>
    getChat(getKey("issueId")).then((data) => {
      const allChats = data.data().chats;
      allChats && setAllChats(allChats);
      const msge = allChats &&  allChats[allChats.length - 1].message;
      const isRemoteVideoPlaying = document.getElementById("remoteVideo").style.display === "block";
      const isChatOpen = document.getElementById("chatContainer");
      if (!isChatOpen && isRemoteVideoPlaying) {
        if (isMessageWithUrl(msge))
          toast.warn(localState.data.CustomShareLabel || t("openLink"), {closeOnClick: true, autoClose: false, onClick: () => openLink(extractMsgeUrl(msge))});
        else toast.warn(t("newMessageChat"), {closeOnClick: true,autoClose: false, onClick: () => {onClickChat()}})
        }
    });

    const clientSignalFromFirebase = () =>
    getSignalCLient(getKey("issueId")).then((data) => {
      const signal = data.data().signal;
      connectionStatuts({Guid:getKey("issueId"), Data:signal})
    });

  const filesFromFirebase = () =>
    getFile(getKey("issueId")).then((data) => {
      const allFiles = data.data().files;
      allFiles && setAllFiles(allFiles);
      const isFileOpen = document.getElementById("fileContainer");
      if (!isFileOpen && allFiles[0].autor === "operator") {
        toast.warn(t("receiveAFile"), {closeOnClick: true,autoClose: 6000, onClick: () => {onClickFile()}});
      }
    });

  const notificacionsFromFirebase = () =>
    getNotification(getKey("issueId")).then((data) => {
      const newNotif = data.data();
      if (newNotif && newNotif.type === "screen")
        onScreenNotification(newNotif);
    });
 
  const onScreenNotification = (notification) => {
    if (notification.data === "on"){
     /*  shareScreenAdjust("on"); */
      setScreenAdjust(true)
    } 
    else {
     /*  shareScreenAdjust("off"); */
      setScreenAdjust(false)
    } 
  };

  /* ANTMEDIA LOGIC */
  const onInvalidStream = (webRtc) =>
    setTimeout(() => webRtc.play(operatorStream()), 2000);

  const onClickVideo = () => {
    if (cameraOn) turnOffCamera(webRTCAdaptor, setCameraOn);
    else turnOnCamera(webRTCAdaptor, setCameraOn);
  };
  
/*   useEffect(()=>{
    if(isIOS()) turnOffCamera(webRTCAdaptor, setCameraOn)
  }, []) */
  const onClickMic = () => {
    if (micOn) turnOffMic(webRTCAdaptor, setMicOn);
    else turnOnMic(webRTCAdaptor, setMicOn);
  };

  const micOff = () => turnOffMic(webRTCAdaptor, setMicOn);
  const camOff = () => turnOffCamera(webRTCAdaptor, setCameraOn);

  const onClickShareScreen = () => {
    if (screenOn)
      turnOffScreen(
        webRTCAdaptor,
        setScreenOn,
        clientStream(),
        getKey("issueId")
      );
    else
      turnOnScreen(
        webRTCAdaptor,
        setScreenOn,
        clientStream(),
        getKey("issueId")
      );
  };

  const onClickCameraSwap = () => {
    cameraSwapMobile( webRTCAdaptor,
      clientStream(),
      videSourceIndex,
      setVideoSourceIndex,
     )
     if (!micOn) {
     setTimeout(()=>{
      turnOffMic(webRTCAdaptor, setMicOn)
     }, 1500)}
  }
  
  

  const onClickChat = () => setShowChat(!showChat);
  const onClickFile = ()=> setShowFiles(!showFiles)

  const onClickResize = () => {if (videoMinimized) setVideoMinimized(false);
    else setVideoMinimized(true);
  };

  const onPlay = () => {
   !errorConnection &&  setShowRemoteVideo(true);
   !errorConnection && setShowWaiting(false);
   !errorConnection && setShowHeaderAndFooter(false);
   !errorConnection && setOpen(true);
   !errorConnection && sendDataSignal();
    clearTimeout(checkStillThereTimeout.current);
  };

  const publishVideo = (webRtc) => {
    setShowWaiting(true);
    setShowButtons(true);
    setShowLocalVideo(true);
    unMuteVideo();
    publishAndPlay(webRtc, clientStream(), operatorStream(), setPublish);
  };

  const unMuteVideo = () =>
    setTimeout(() => {document.getElementById("remoteVideo").muted = false;
    }, 2000);

  const answerCancelAndExit = () => {
    setAlert("cancelCall");
  };

  const landscape = () =>
    window.addEventListener("orientationchange", function rotate() {
      if (window.orientation === 90 || window.orientation === -90) {
        setRotate(true);
        setShowChat(false);
      } else {
        setRotate(false);
      }
    });


  useEffect(() => {
    landscape();
  }, [rotate]);

  const getStatistics =() =>{
    getStats(webRTCAdaptor, clientStream())
   }
  
   useEffect(()=>{
     if (showRemoteVideo && !errorConnection) {
         getStatistics()
         startCheckRtt()
         return (()=>{
           disableStats(webRTCAdaptor, clientStream())
           clearInterval(checkRttInterval.current);
          })
        }
      },[showRemoteVideo])

   /*    useEffect(() => {
        if (showRemoteVideo && !errorConnection) sendSignalClient(getKey("issueId"), { "signal": roundTripTime, "created":moment().valueOf()})}, [roundTripTime]); */

       /*  const [adaptative, setAdaptative]=useState(false) */
   /*      useEffect(()=>{
          if(showRemoteVideo){
            console.log("apague")
            onClickVideo()
            setAdaptative(true)
          }
          }, [showRemoteVideo])
          
          useEffect(()=>{
            if(adaptative){
              setTimeout(()=>{
                console.log("prendi")
                onClickVideo()
                setAdaptative(false)
              }, 100)
            }
          }, [adaptative])    */ 
          
          
  return (
    <PageWrapper
      showHeader={showHeaderAndFooter}
      showFooter={showHeaderAndFooter}
    >
      <div className={showRemoteVideo ? "antCallContainerOnCall":"antCallContainer"}>
        <button style={{ display: "none" }} id="publish" />
        <AntMediaRemoteVideo showRemoteVideo={showRemoteVideo} rotate={rotate} screenAdjust={screenAdjust} />
        <video id="mergeVideo" muted playsInline autoPlay style={{ display: "none" }} />
        <video id="remoteVideo2" muted playsInline autoPlay style={{ display: "none" }} />
        <AntMediaScreen showScreen={showScreen} videoMinimized={videoMinimized} />
        <AntMediaVideo cameraOn={cameraOn} showLocalVideo={showLocalVideo} showScreen={showScreen} videoMinimized={videoMinimized} showRemoteVideo={showRemoteVideo} />
        {!showLocalVideo && <AntMediaPermissions />}
        {showWaiting && ( <AntMediaWaiting spinner={localState.data.CustomLoadingGif} messageWaiting = {localState.data.WaitingBody} />)}
        {showButtons && (
          <AntMediaNewButtons
            onClickVideo={onClickVideo}
            onClickMic={onClickMic}
            onClickShareScreen={onClickShareScreen}
            onClickCameraSwap={onClickCameraSwap}
            onClickChat={onClickChat}
            onClickResize={onClickResize}
            onClickEndCall={answerCancelAndExit}
            cameraOn={cameraOn}
            micOn={micOn}
            screenOn={screenOn}
            chatOn={showChat}
            videoMinimized={videoMinimized}
            callOn={!showWaiting}
            showLocalVideo={showLocalVideo}
            showRemoteVideo={showRemoteVideo}
            isOpen={isOpen}
            setOpen={setOpen}
            rotate={rotate}
            roundTripTime={roundTripTime}
          />
        )}
        {!showFiles && showChat && (
          <AntMediaChat
            customShareLabel={localState.data.CustomShareLabel}
            openLink={openLink}
            allChats={allChats}
            name={localState.submit.Name}
            lastName={localState.submit.Lastname}
            logo={localState.data.Logo}
            open={showChat}
            showChat={onClickChat}
            setShowFiles={setShowFiles}
            showFiles={showFiles}
            AllowFileShare={localState.data.AllowFileShare}
            colorPrimary={localState.data.ColorPrimary} 
          />
        )}
        {showFiles && (<AntMediaFiles showFiles={showFiles} setShowFiles={setShowFiles} colorPrimary={localState.data.ColorPrimary}        allFiles={allFiles}/>
        )}
        {callAlert && (<AntMediaAlert name={callAlert} type="alert"onClickLeft={showWaiting ? cancelTicket : finishTicket} onClickRight={onAcceptStillThere} />
        )}
        {error && (<AntMediaAlert name={error} type="error" onClickLeft={generateOfflineIssue} message={messageStoreClosed}  />
        )}
        {notPermissions && (<AntMediaAlert text={alertContent} title={t("attention")} leftButton="Entendido" onClickLeft={() => notpermits()} />
        )}
        {errorConnection && (<AntMediaAlert text={t("unknkowError")} title={t("attention")} leftButton="Entendido" onClickLeft={() => errorConectWebrtc()} />
        )}
           {errorConnectionOperator && (<AntMediaAlert text={t("errorConnectionOperator")} title={t("attention")} leftButton="Entendido" onClickLeft={() => errorConectWebrtc()} />
        )}
        <ToastContainer position="bottom-center" autoClose={3000} hideProgressBar newestOnTop={false} closeOnClick={false} rtl={false}     pauseOnFocusLoss draggable={false} pauseOnHover/>
        {showIframe && ( <AntMediaIframe link={linkUrl} closeIframe={closeIframe} />)}
      </div>
    </PageWrapper>
  );
};

export default AntMediaCall;
