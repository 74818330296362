import React from "react";
import { IonImg } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import PageWrapper from "../../components/PageWrapper";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";
import Selector from "../../components/Selector";
import SearchableSelector from "../../components/SearchableSelector";

import t from "../../utils/translations";

const Locations = ({
	createCitiesOptions,
	createCountriesOptions,
	createProvincesOptions,
	handleSubmit,
	loading,
	showFooter,
	selectedCity,
	selectedCountry,
	selectedProvince,
	handleSelectedCity,
	handleSelectedCountry,
	handleSelectedProvince,
	icon
}) => 
	loading ? <Spinner /> : (
		<PageWrapper showFooter={showFooter} showHeader={true}>
			{icon && (
				<div className="centerText marginToHeader">
					<IonImg className="smileIcon" src={icon} />
				</div>
			)}
			<h2>{t("selectResidenceOptions")}</h2>
			<div className="smallMarginHorizontal marginToHeader columnContainerWithFlex">
				<Selector
					disabled={false}
					placeholder={t("selectCountry")}
					value={selectedCountry}
					onChange={e => handleSelectedCountry(e.detail.value)}
				>
					{createCountriesOptions()}
				</Selector>
				
				<SearchableSelector
					isSearchable
					className="applyCustomBoxStyle"
					isDisabled={!selectedCountry}
					options={createProvincesOptions()}
					placeholder={t("selectProvince")}
					value={selectedProvince}
					onChange={e => handleSelectedProvince(e)}
				/>

				{/* <SearchableSelector
					isSearchable
					className="applyCustomBoxStyle"
					isDisabled={!selectedProvince}
					options={createCitiesOptions()}
					placeholder={t("selectCity")}
					value={selectedCity}
					onChange={e => handleSelectedCity(e)}
				/> */}

				<Button className="alignSelfCenter" disabled={!selectedCountry || !selectedProvince } size={"large"} onClick={handleSubmit}>
					<FontAwesomeIcon className="arrowIconAdjust" icon={faChevronRight} size={"xs"} />
					{t("continue")}
				</Button>
			</div>
		</PageWrapper>
	);

export default Locations;