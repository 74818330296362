import React from "react";
import { IonInput } from "@ionic/react";

import "./Input.scss";

const Input = ({  debounce, disabled, id, inputmode, maxlength, minlength, name, onChange, pattern, placeholder, required, type, value }) => {

	return (
		<>
			<IonInput
				autocomplete="off"
				className="input applyCustomBoxStyle"
				debounce={debounce}
				disabled={disabled}
				id={id}
				inputmode={inputmode}
				maxlength={maxlength}
				minlength={minlength}
				name={name}
				pattern={pattern}
				placeholder={placeholder}
				required={required}
				type={type}
				value={value}
				onIonChange={onChange}
			/>
		</>
	);
};

export default Input;
