import React, { useState, useEffect } from "react";
import { IonImg } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Radio from "../components/Radio";
import Button from "../components/Button";
import Spinner from "../components/Spinner";
import PageWrapper from "../components/PageWrapper";

import t from "../utils/translations";
import { getKey } from "../utils/localStorage";
import { setCssColor } from "../utils/colors";
import { createStore } from "../services/api";
import { landingOnLoad } from "../utils/landingLoad";

const Stores = ({ location, history }) => {
	const [loading, setLoading] = useState(true);
	const [localState,] = useState(location.state);
	const [selected, setSelected] = useState(null);
	
	useEffect(() => {
		landingOnLoad(localState, history, () => setLoading(false), () => setLoading(true))
	}, [localState, history]);

	const sendSampleStore = (idIssue, idStore) => {
		const asociatedDepartments = localState.data.Departments.filter(d => d.IdStore === selected);
		const isSampleStore = asociatedDepartments.length === 1;
		if (isSampleStore) {
			const uniqueDeparment = asociatedDepartments[0].Guid;
			const store = { IdStore: idStore, IdDepartment: uniqueDeparment, IdIssue: idIssue}
			createStore(store);
		};
	};

	const sendStoreWithoutDeparment = (idIssue, idStore) => {
		const store = { IdStore: idStore, IdDepartment: null, IdIssue: idIssue}
		createStore(store);
	};

	
	const handleSubmit = event => {
		event && event.preventDefault();
		const stateWithSelectedStore = { ...localState };
		stateWithSelectedStore.submit.IdStore = selected;
		const selectedStore = localState.data.Store.filter(store => store.Guid === selected)[0];
		stateWithSelectedStore.config.Block = selectedStore.Block;
		stateWithSelectedStore.config.IsOpen = selectedStore.IsOpen;
		stateWithSelectedStore.config.storeName = selectedStore.Name;
		const { routes, submit } = stateWithSelectedStore;
		
		if (!localState.data.AllowDepartment) sendStoreWithoutDeparment(submit.IdIssue, selected);
		else sendSampleStore(submit.IdIssue, selected);
		
		const nextRoute = routes.shift();
		history.push({
			pathname: nextRoute,
			state: stateWithSelectedStore
		});
	};
	
	return loading ? <Spinner /> : (
		<PageWrapper showFooter={localState.data.ShowFooter} showHeader={true} localState={localState}>
			<div className="smallMarginHorizontal">
				{localState.data.Icon && (
					<div className="centerText marginToHeader">
						<IonImg className="smileIcon" src={localState.data.Icon} />
					</div>
				)}
				<h2>{localState.data.StoreTitle || t("selectStore")}</h2>
				<h3 className="paragraphEnd">{localState.data.StoreBody}</h3>
				<form className="smallMarginHorizontal columnContainerWithFlex" onSubmit={handleSubmit}>
					<Radio elems={localState.data.Store} value={selected} onChange={e => setSelected(e.detail.value)}/>
					<Button className="alignSelfCenter" disabled={!selected} size={"large"} type="submit">
						<FontAwesomeIcon className="arrowIconAdjust" icon={faChevronRight} size={"xs"} />
						{t("continue")}
					</Button>
				</form>
			</div>
		</PageWrapper>
	);
};

export default Stores;
