import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { isLanding } from "./helpers";

// Initiators
export const initializateUA = idUA => idUA ? initUA(idUA) : null;
export const initializateGA4 = idGA4 =>  idGA4 ? initGA4(idGA4) : null;
export const initializateFBQ = idFQB => idFQB ? initFBQ(idFQB) : null;
export const initializateAW = idAW => idAW ? initAW(idAW) : null;

// Events senders.
export const sendFBQevent = (eventName, extraData) => ReactPixel.track(eventName, extraData)

export const sendPropertyEvent = eventName => {
  window.dataLayer = window.dataLayer || [];
  function gtag(){
    window.dataLayer.push(arguments);
  };
  sendUAEvent(eventName);
  gtag('event', eventName);
};

export const sendUAEvent = eventName => ReactGA.event({
  category: "general",
  action: eventName,
  label: "general",
});


export const sendAWEvent = (id, cTag) => {
  window.dataLayer = window.dataLayer || [];
  function gtag(){
    window.dataLayer.push(arguments);
  };
  gtag('event', 'conversion', {
    'send_to': `${id}/${cTag}`,
  });
};

// Initiators helpers
const initGA4 = id => {
  //if (!gtagInstalled()) installGTAG(id);
  window.dataLayer = window.dataLayer || [];
  function gtag(){
    window.dataLayer.push(arguments);
  };
  gtag('js', new Date());
  gtag('config', id);
};

const initFBQ = id => {
  ReactPixel.init(id);
  ReactPixel.pageView();
};

const initUA = id => {
  ReactGA.initialize(id);
  ReactGA.pageview(window.location.pathname + window.location.search);
};

const initAW = id => {
  if (!gtagInstalled()) installGTAG(id);
  window.dataLayer = window.dataLayer || [];
  function gtag(){
    window.dataLayer.push(arguments);
  };
  gtag('js', new Date());
  gtag('config', id);
}

// For use gtag needed installation, just one time.
export const installGTAG = id => {
  var gtagScript = document.createElement("SCRIPT");
  gtagScript.id = "triggering"
  gtagScript.async = true;
  gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
  document.head.append(gtagScript);
};

export const gtagInstalled = () =>  document.getElementById("triggering");

// Others
export const fbVerification = tagContent => {
  var fbDoamin = document.createElement('meta');
  fbDoamin.name = "facebook-domain-verification";
  fbDoamin.content = tagContent;
  document.getElementsByTagName('head')[0].appendChild(fbDoamin);
};

export const sendPixelTrack = (landingName, trackName, trackData) => {
  if (isLanding(landingName)) ReactPixel.track(trackName, trackData);
};
