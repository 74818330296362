import React, { useState, useEffect } from "react";
import { IonImg } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Radio from "../components/Radio";
import Button from "../components/Button";
import Spinner from "../components/Spinner";
import PageWrapper from "../components/PageWrapper";

import t from "../utils/translations";
import { createStore } from "../services/api";
import { landingOnLoad } from "../utils/landingLoad";

const Departments = ({ location, history }) => {
	const [loading, setLoading] = useState(true);
	const [localState,] = useState(location.state);
	const [selected, setSelected] = useState(null);

	const checkSampleStore = () => {
		const asociatedDepartments = localState.data.Departments.filter(d => d.IdStore === localState.submit.IdStore);
		const stateWithSelectedDepartment = { ...localState };
		stateWithSelectedDepartment.submit.IdDepartment = asociatedDepartments[0].Guid;
		const nextRoute = localState.routes.shift();
		if (asociatedDepartments.length === 1)
			history.push({
				pathname: nextRoute,
				state: stateWithSelectedDepartment
			});
	};

	useEffect(() => {
		landingOnLoad(localState, history, () => setLoading(false), () => setLoading(true));
		checkSampleStore();
	}, [localState, history]);

	const sendStore = (idIssue, idStore, idDeparment) => {
		const store = { IdStore: idStore, IdDepartment: idDeparment, IdIssue: idIssue}
		createStore(store);
	};

	const handleSubmit = event => {
		event && event.preventDefault();
		const stateWithSelectedDepartment = { ...localState };
		stateWithSelectedDepartment.submit.IdDepartment = selected;
		const { routes, submit } = stateWithSelectedDepartment;

		/* console.log(submit.IdIssue, submit.IdStore, stateWithSelectedDepartment.submit.IdDepartment) */

		sendStore(submit.IdIssue, submit.IdStore, stateWithSelectedDepartment.submit.IdDepartment);
		
		const nextRoute = routes.shift();
		history.push({
			pathname: nextRoute,
			state: stateWithSelectedDepartment
		});
	};

	return loading ? <Spinner /> : (
		<PageWrapper showFooter={localState.data.ShowFooter} showHeader={true} localState={localState}>
			{localState.data.Icon && (
				<div className="centerText marginToHeader">
					<IonImg className="smileIcon" src={localState.data.Icon} />
				</div>
			)}
			<h2>{localState.data.DepartmentTitle || t("selectDepartment")}</h2>
			<h3 className="paragraphEnd">{localState.data.DepartmentBody}</h3>
			<form className="smallMarginHorizontal columnContainerWithFlex" onSubmit={handleSubmit}>
				<Radio elems={localState.data.Departments.filter(department => department.IdStore === localState.submit.IdStore)} value={selected} onChange={e => setSelected(e.detail.value)} />
				<Button className="alignSelfCenter" disabled={!selected} size={"large"} type="submit">
					<FontAwesomeIcon className="arrowIconAdjust" icon={faChevronRight} size={"xs"} />
					{t("continue")}
				</Button>
			</form>
		</PageWrapper>
	);
};

export default Departments;
