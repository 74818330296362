import React from "react";
import './Spinner.css'

const Spinner = ({size, color, marginLeft, marginRight, }) => {
    const style = {
        width: size,
        height: size,
        borderLeftColor: color,
        marginLeft:marginLeft || "none",
        marginRight: marginRight || "none",
       
    }
    return(
        <div className='spinner' style={style} ></div>
    )
}
export default Spinner