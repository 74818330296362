import React from "react";
import { getKey } from "../utils/localStorage";
import { inIframe, isMobileAgent } from "../utils/helpers";
import "./Header.scss";

const Header = () => {
	const imgHeader = getKey("header-img");
	const isDesktop = window.screen.width > 800 && !inIframe() && !isMobileAgent();

	return !isDesktop ? (
		<div style={{ zIndex: "-20" }}>
			<div className={!imgHeader ? "coloredBackgroundMobile" : "headerImgMobile"} />
		</div>
	) : null
};
export default Header;