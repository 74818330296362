import React from "react";
import { IonImg } from "@ionic/react";
import { TwitterShareButton, FacebookShareButton, WhatsappShareButton } from "react-share";

const ShareButton = ({ name, text, url, onClick }) => {
	const componentMapper = () => {
		switch (name) {
			case "facebook":
				return (
					<FacebookShareButton onClick={onClick} quote={text} url={url}>
						<IonImg className="smileIcon" src="/img/facebook-icon.png" />
					</FacebookShareButton>
				);

			case "whatsapp":
				return (
					<WhatsappShareButton onClick={onClick} title={text} url={url}>
						<IonImg className="smileIcon" src="/img/whatsapp-icon.png" />
					</WhatsappShareButton>
				);
			case "twitter":
				return (
					<TwitterShareButton onClick={onClick} title={text} url={url}>
						<IonImg className="smileIcon" src='/img/twitter-icon.png' />
					</TwitterShareButton>
				);
			default:
				return;
		}
	};

	return componentMapper();
};

export default ShareButton;
