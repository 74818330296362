import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import "./PageWrapper.scss"
import Footer from "../components/Footer";
import Header from "../components/Header";
import { inIframe, isMobileAgent } from "../utils/helpers";

const PageWrapper = ({ children, showFooter, showHeader, fullWidth, relative, top,  }) => {

const content = window.location.pathname.includes("4")

	const isDesktop = window.screen.width > 800 && !inIframe() && !isMobileAgent();
	return !isDesktop ? (
			<IonPage>
				<IonContent>
					{showHeader && <Header />}
					{showHeader && 
						<div className="headerflexCenter">
							<div className="borderFrame">
								<div className="subheader" />
							</div>
						</div>}
					<div style={top ? { top: "0" } : null} className={!fullWidth ? "contentContainer" : "contentContainerFull"}>
						<div className={!content && "content"} style={fullWidth ? { padding: "0px" } : null}>
							{children}
						</div>
					</div>
				</IonContent>
				{showFooter && !inIframe() && <Footer relative={relative} />}
		</IonPage>
		) : <> {children} </>
};

export default PageWrapper;