import React from "react";

import PageWrapper from "../components/PageWrapper";
import Error from "../components/Error";
import t from "../utils/translations";

const NoPage = () => (
	<PageWrapper className="smallMarginHorizontal columnContainerWithFlex" showFooter={true} showHeader={true}>
		<Error
			errorMessage={t("error404Message")}
			errorTitle={t("error404")}
		/>
	</PageWrapper>
);

export default NoPage;
