import React from "react";
import { IonModal, IonButton } from "@ionic/react";

import "./Modal.scss";

const Modal = ({ className, children, isOpen, onClickClose }) => (
	<div>
		<IonModal
			cssClass={["modal", className]}
			isOpen={isOpen}
			swipeToClose={true}
		>
			<IonButton className="closeButton" onClick={onClickClose}>X</IonButton>
			{children}
		</IonModal>
	</div>
);

export default Modal;