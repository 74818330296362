import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Home from "./pages/Home/Home";
import Recomendations from "./pages/Recomendations";
import LocationsContainer from "./pages/Locations/index";
import Stores from "./pages/Stores";
import Departments from "./pages/Departments";
import Questions from "./pages/Questions";
import OpenTok from "./pages/Videocall/Opentok/OpentokCall";
import AntMediaCall from "./pages/Videocall/AntMedia/AntMediaCall";
import Appointment from "./pages/Appointment/Appointment";
import Feedback from "./pages/Feedback";
import Thanks from "./pages/Thanks";
import NoPage from "./pages/NoPage";
import BrowserChange from "./pages/BrowserChange/BrowserChange";
import DesktopWrapper from "./components/DesktopWrapper";
import BoundaryError from "./components/BoundaryError";

import { gSender } from "./utils/helpers";


const Routes = ({ data }) => (
	<BoundaryError>
		<BrowserRouter>
			<Route render={({ history, location }) => {
				history.listen((location) => window._mfq && window._mfq.push(["newPageView", location.pathname]));
				gSender(data.GA4, data.UA, data.FBQ, data.PG);
				return (
					<TransitionGroup>
						<DesktopWrapper data={data}>
							<CSSTransition key={location.key} classNames="fade" timeout={200}>
								<Switch location={location}>
									<Route exact component={Recomendations} path="/:query/1" />
									<Route exact component={LocationsContainer} path="/:query/1-0" />
									<Route exact component={Stores} path="/:query/1-1" />
									<Route exact component={Departments} path="/:query/2" />
									<Route exact component={Questions} path="/:query/3" />
									<Route exact component={OpenTok} path="/:query/4" />
									<Route exact component={AntMediaCall} path="/:query/4-1" />
									<Route exact component={Appointment} path="/:query/4-2" />
									<Route exact component={Feedback} path="/:query/5" />
									<Route exact component={Thanks} path="/:query/6" />
									<Route exact component={NoPage} path="/404" />
									<Route exact render={props => <Home {...props} data={data} />} path="/:query" />
									<Route exact component={NoPage} path="/" />
									<Route exact component={BrowserChange} path="/:query/browserChange" />
								</Switch>
							</CSSTransition>
						</DesktopWrapper>
					</TransitionGroup>
				);
			}} />
		</BrowserRouter>
	</BoundaryError>
);
export default Routes;
