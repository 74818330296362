import React from "react";
import Draggable from 'react-draggable';
import { inIframe, isIOS, isMobileAgent } from "../../../utils/helpers";
import "./AntMedia.css";

const AntMediaVideo = ({ showLocalVideo, videoMinimized, showScreen, cameraOn, showRemoteVideo}) => {
    const maxBottom = window.innerHeight * 0.40
    const maxLeft = window.innerWidth * 0.50
    const isDesktop = window.screen.width > 800 && !inIframe() && !isMobileAgent();

    const draggableOptions = {
        bounds: { top: 0, left: -maxLeft, right: 0, bottom: maxBottom },
        cn: showLocalVideo ? "draggableVideo" : "draggableVideoHidden",
        pos: { x: 0, y: 0 },
        grid: [0, 0],
    };

    const videoStyles = {
        mobile: { height: videoMinimized ? "105px" : "210px", width: videoMinimized ? "75px" : "150px", right: "1.5rem", display: isIOS() && !cameraOn ? "none" : "flex" , /*  position: isIOS() ? "relative" : null, top:isIOS() ? "-237px" : null, left: isIOS() ? "-25px" :null */ },
        desktop: { height: videoMinimized ? "105px" : "210px", width: videoMinimized ? "75px" : "150px", display: isIOS() && !cameraOn ? "none" : "flex",}
    };

    const noVideoStyles = {
        mobile: { height: videoMinimized ? "105px" : "210px", width: videoMinimized ? "75px" : "150px", right: "1.5rem", display: (!showLocalVideo && !showScreen) || !cameraOn ? "flex" : "none " },
        desktop: { height: videoMinimized ? "105px" : "210px", width: videoMinimized ? "75px" : "150px",  marginLeft: videoMinimized ? "-75px" : "-150px", display: (!showLocalVideo && !showScreen) || !cameraOn ? "flex" : "none " }
    };

    return isDesktop ? (
        <div className="localVideoDesktopContainer">
            <video id="localVideo" muted playsInline autoPlay className="localVideoDesktop" style={videoStyles.desktop} />
            <div id="noLocalVideo" className="noVideoDesktop" style={noVideoStyles.desktop}>
                <img src="https://icongr.am/feather/user.svg?size=50&color=ffffff" />
            </div>
        </div>
    ) : (
        <div style={ (showRemoteVideo &&  isIOS() ) ? {position: "fixed" , top: "-237px", left: "-25px"} : null}>
            <video id="localVideo" muted playsInline autoPlay className={showRemoteVideo ? "localVideoMobileOnCall" : "localVideoMobile"} style={videoStyles.mobile} />
            <div id="noLocalVideo" className={ showRemoteVideo ? "noVideoMobileOnCall" : "noVideoMobile"} style={noVideoStyles.mobile}>
                <img src="https://icongr.am/feather/user.svg?size=50&color=ffffff" />
            </div>
        </div>
    );
};

export default AntMediaVideo;
