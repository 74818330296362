import React from "react";
import { IonPage, IonContent, IonSpinner } from "@ionic/react";

const Spinner = ({ className }) => (
	<IonPage className={className}>
		<IonContent>
			<div className="centerContent" >
				<IonSpinner />
			</div>
		</IonContent>
	</IonPage>
);

export default Spinner;
