import React, { useCallback, useRef, useState } from 'react';
import { IonSlides, IonSlide, IonContent } from '@ionic/react';

import "./Slides.scss"
// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.

export const Slides = ({ options }) => {

  const ref = useCallback((slidesRef) => {
    if (slidesRef) {
      setInterval(() => {
        slidesRef.slideNext()
      }, 20000);
    }
  }, [])



  const slideOptsOne = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: false
  };
  return (
    <IonContent className="slideContainer" justify-content-center align-items-center style={{ marginTop: "30px", background: "var(--primary-color", borderRadius: "15px" }}>
      <IonSlides ref={ref} className="sliderContent" pager={true} autoHeight={true} options={slideOptsOne}>
        {options.map(opt =>
          <IonSlide className="slides">
            {opt}
          </IonSlide>
        )
        }
      </IonSlides>
    </IonContent>
  )
};

export default Slides