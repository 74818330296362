import React, { useCallback } from 'react';
import { IonSlides, IonSlide, IonContent } from '@ionic/react';
import { inIframe } from '../../../utils/helpers';

export const AntMediaSlides = ({ options }) => {
  const ref = useCallback(slidesRef => slidesRef && setInterval(() => slidesRef.slideNext(), 20000), []);

  const slideOptsOne = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: false
  };

  const ionStyle = {
    content: {
        width:  inIframe() ? "180px" : "250px",
        height:  inIframe() &&  "180px" ,
        zIndex: "0",
    },
    slides: {
        background: "var(--primary-color)",
        "--bullet-background-active": "white",
        borderRadius: "15px",
    }
  };


  return (
    <IonContent style={ionStyle.content}>
      <IonSlides style={ionStyle.slides} ref={ref} pager={true} autoHeight={true} options={slideOptsOne}>
        {options.map((opt, i)=> <IonSlide key={i}> {opt} </IonSlide>)}
      </IonSlides>
    </IonContent>
  );
};

export default AntMediaSlides