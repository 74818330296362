import React from 'react';
import * as bodyPix from '@tensorflow-models/body-pix';
import * as tf from '@tensorflow/tfjs';
import "./Croma.scss";

class Croma extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        net:null,
        style:{
            display:'none',
        },
        config: {
            architecture: 'MobileNetV1',
            outputStride: 16,
            multiplier: 1,
            quantBytes: 2
        },
        };
        this.videoTag = React.createRef();
        this.canvasTag= React.createRef();
        this.drawBody= this.drawBody.bind(this);
        this.detectBody = this.detectBody.bind(this);
    };

componentDidMount() {
    this.videoTag.current.srcObject = this.props.stream
    
    this.videoTag.current.onloadeddata = () => {
            if (this.state.net==null){   
                bodyPix.load(this.state.config)
                .then(objNet => {
                    this.setState({net:objNet});  
                    this.detectBody(); 
                });
            };
    };
};


detectBody() {
  if (this.state.net!=null) this.state.net.segmentPerson(this.videoTag.current, {
        flipHorizontal:true,
        internalResolution:'low',
        segmentationThreshold:0.33
    })
    .catch(err=> console.log(err))
    .then(personsegmentation => this.drawBody(personsegmentation));
  
  requestAnimationFrame(this.detectBody);
};

drawBody(personSegmentation){
    this.canvasTag.current.getContext('2d').drawImage(this.videoTag.current, 0, 0, this.props.width , this.props.height );
    var imageData= this.canvasTag.current.getContext('2d').getImageData(0,0,this.props.width ,this.props.height );
    var pixel = imageData.data;
    for (var p = 0; p < pixel.length; p += 4){
        if (personSegmentation.data[p/4] == 0) pixel[p+3] = 0;  
    };
    
    this.canvasTag.current.getContext('2d').imageSmoothingEnabled = true;
    this.canvasTag.current.getContext('2d').putImageData(imageData,0,0);
};

render() {
    return (
        <div className="croma-background">
            <img src={this.props.backgroundImage.Image} alt=".." />
            <div style={{ marginTop: "50%" }}>
                <video 
                    id="bodycam"
                    ref={this.videoTag}
                    width={this.props.width}
                    height={this.props.height}
                    autoPlay
                    style={this.state.style}>
                </video>
                <canvas className="person" ref={this.canvasTag}  width={this.props.width} height={this.props.height}></canvas> 
            </div>
        </div>
    )
}

}

export default Croma;