import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import DOMPurify from "dompurify";

import Button from "../components/Button";
import Spinner from "../components/Spinner";
import PageWrapper from "../components/PageWrapper";

import t from "../utils/translations";
import { getKey } from "../utils/localStorage";
import { setCssColor } from "../utils/colors";
import { backCheck } from "../utils/backCheck";
import { landingOnLoad } from "../utils/landingLoad";

const Recomendations = ({ location, history }) => {
	const [loading, setLoading] = useState(true);
	const [localState,] = useState(location.state);

	useEffect(() => {
		landingOnLoad(localState, history, () => setLoading(false), () => setLoading(true));
	}, [localState, history]);

	const handleSubmit = () => {
		const { routes } = localState;
		const nextRoute = routes.shift();
		history.push({
			pathname: nextRoute,
			state: localState,
		});
	};

	const determinateImagen = () => {
		let img;
		if (window.screen.width > 800) {
			img = { __html: DOMPurify.sanitize(localState.data.Recomendation) }
		} else {
			img = { __html: DOMPurify.sanitize(localState.data.RecomendationMobile) }
		}
		return img;
	}

	return loading ? <Spinner /> : (
		<PageWrapper showFooter={localState.data.ShowFooter} showHeader={true} localState={localState}>
			<div className="smallMarginHorizontal columnContainerWithFlex">
				<div style={{"marginTop":"50px", "marginBottom":"50px"}} dangerouslySetInnerHTML={determinateImagen()} />
				<Button className="alignSelfCenter" size={"large"} onClick={handleSubmit}>
					<FontAwesomeIcon className="arrowIconAdjust" icon={faChevronRight} size={"xs"} />
					{t("continue")}
				</Button>
			</div>
		</PageWrapper>
	);
};

export default Recomendations;
