import React from "react";
import { IonTextarea } from "@ionic/react";

import "./TextArea.scss";

const TextArea = ({ className, placeholder, value, onChange }) => {

	return (
		<>
			<IonTextarea className={["applyCustomBoxStyle textArea", className].join(" ")} placeholder={placeholder} value={value} onIonChange={onChange}/>
		</>
	);
};

export default TextArea;
