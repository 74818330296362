import React, { useState, useEffect } from 'react'
import t from "../utils/translations";
import "./ScreenPermits.scss"
import { sendPixelTrack } from "../utils/analytics";
import {sendEvent, inIframe} from "../utils/helpers"
import { issueDenied } from '../services/api';
import { getKey } from '../utils/localStorage';

export const ScreenPermits = ({ onClick, setPreloadWaitingScreen, location, hideButtons }) => {
    const [localState] = useState(location.state);
    const [showAlert, setShowAlert] = useState(false);
    const [update, setUpdate] = useState(true);
    const [dennySended, setDennySended] = useState(false);
    const height = window.screen.height;
    const desktop = window.screen.width > 600;
    const smallHeight = window.screen.height < 558;
    const trackInfo = { value: 1, currency: 'COP', content_name: 'permitir-camara-y-microfono' };
    
    const devicesNeeded = devices => {
        var audioInput;
        var videoInput;
        devices.forEach(device => {
            if (device.kind === "audioinput") audioInput = true;
            if (device.kind === "videoinput") videoInput = true;
        });
        return audioInput && videoInput; 
    };

    const onAccept = disableCam => {
        navigator.mediaDevices.enumerateDevices().then(devices => {
            if(devicesNeeded(devices)){
                onClick();
                sendEvent("Click camara y micrófono");
                setPreloadWaitingScreen(true);
                sendPixelTrack("segurosmundial", "InitiateCheckout", trackInfo);  
            };
        });
    };
    
   const constraints = (window.constraints = {
      audio: true,
      video: true,
    });
    const storeIsOpen = localState.config.IsOpen
     
      useEffect(() => {

      setTimeout(() => {
        storeIsOpen &&   navigator.mediaDevices.getUserMedia(constraints)
          .then(() => localState.config.IsOpen &&  onAccept(true))
          .catch(() => {
            !dennySended && issueDenied({ Guid: getKey("issueGuid") });
            setDennySended(true);
            setShowAlert(true);
            setUpdate(!update);
          });
      }, 5000);
    }, [update]);

    return (
      <div className={desktop ? "App-mask-white-Desktop columnContainerWithFlex" : "App-mask-white columnContainerWithFlex"} >
           
                    <div className="containerCall">
                <div style={smallHeight ? { bottom: "-6%", height: "158px", display: "flex", justifyContent: "center" } : height ? { bottom: "-14%" } : null} className="waitingScreenText">
                <img alt="waiting" src="\img\camera_person.png"  className= {inIframe() ? "cameraOffIframe" : "cameraOff"} />
                    {!smallHeight && (
                        <div className="vectorContainer">
                                : <img alt="waiting" src="\img\waiting-room.png" height="60px" />
                        </div>
                    )}
                    <div>
                        <div 
                            style={smallHeight ? { marginTop: "10px", width: "auto" } : null} 
                            className="waitingScreenText bigger"
                        >
                
                        </div>
                        <div 
                            style={smallHeight ? { width: "auto", marginTop: "0px" } : null} 
                            className="waitingScreenText"
                        >
                            
                        </div>
                    </div>
                </div>
            </div>
        
            <div className="importants" >
                <p >{t("waitoperator")}</p>
                <p style={{ color: "#656565" }}>{t("permitsCall")}</p> 
            </div> 
           <div className="screenPermitsOne">
            
                        <div className="screenPermitsCamera">
                            <div className="">
                            <img  style={{height:"150px", marginTop:"20px"}} src={t("gifPermits")} alt="" />                
                            </div>
                          </div>
                    </div>
                        {!hideButtons && <div className="screenPermitsIcons">
                            <div className="screenPermitsIconsItems">
                                <img style={{height:"42px", width:"42px"}} src="/img/microfono.png" alt="" />
                            </div>
                            <div className="screenPermitsIconsItems">
                            <img style={{height:"42px", width:"42px"}} src="/img/camara apagada.png" alt="" />
                            </div>
                            <div className="screenPermitsIconsItemsRed">
                            <img style={{height:"6vh", width:"6vh"}} src="/img/cortar.png" alt="" />
                            </div>
                            <div className="screenPermitsIconsItems">
                            <img style={{height:"45px", width:"47px", backgroundColor:"white"}} src="/img/camaraRotar.png" alt="" />
                            </div>
                            <div className="screenPermitsIconsItems">
                            <img style={{height:"45px", width:"47px", backgroundColor:"white"}} src="/img/boton de chat.png" alt="" />
                            </div>
                            
                        </div>}
            {showAlert &&
                <div className="alertContainer">
                    <div className="main">
                    {t("accessSources")}  <img style={{height:"16px", width:"16px", backgroundColor:"white"}} src="/img/lock.png" alt="" />  {t("accessSourcesTwo")}
                        
                    </div>
                    <div className="rejectButtons">
                        <div className="pointer" onClick={() => setShowAlert(false)}>Cerrar</div>
                    </div>
                </div>}

        </div>
    );
}


export default ScreenPermits