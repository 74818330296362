import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import t from "../utils/translations";
import Score from "../components/Score";
import TextArea from "../components/TextArea";
import Button from "../components/Button";
import Spinner from "../components/Spinner";
import PageWrapper from "../components/PageWrapper";

import { getKey } from "../utils/localStorage";
import { scoreIssue } from "../services/api";
import { landingOnLoad } from "../utils/landingLoad";
import { classByAgent, inIframe, isMobileAgent } from "../utils/helpers";

const Feedback = ({ history, location }) => {
	const [loading, setLoading] = useState(true);
	const [localState,] = useState(location.state);
	const [Comment, setComment] = useState(null);
	const [Selected, setSelected] = useState(null);
	const [calificated, setCalificated] = useState(false);
	
	useEffect(() => {
		landingOnLoad(localState, history, () => onSuccessLoad(), () => setLoading(true))
	}, [localState, calificated, history]);

	const onSuccessLoad = () => {
		if (localState.data.FeedbacksTopic) checkForModalClosing();
		setLoading(false);
	};
 
	const handleSubmit = () => {
		const { routes } = localState;
		const nextRoute = routes.shift();
		scoreIssue({
			Score: Selected,
			Guid: localState.config.guid,
			Comment: Comment ? Comment : null
		})
			.then(() =>
				history.push({
					pathname: nextRoute,
					state: localState
				})
			);
	};

	let desktop = window.screen.width > 800

	const checkForModalClosing = () => {
		window.addEventListener("message", function (event) {
			if (event.origin === "https://feedbacks.digital") window.closeModal();
		});
	};

	window.closeModal = function () {
		setCalificated(true);
		const { routes } = localState;
		const nextRoute = routes.shift();
		history.push({
			pathname: nextRoute,
			state: localState
		})
	};


	return loading ? <Spinner /> : (
		!localState.data.FeedbacksTopic ? (
			<PageWrapper showFooter={localState.data.ShowFooter} showHeader={true} localState={localState}>
				<div style={desktop && !inIframe() ? { width: "40%", margin: "auto" } : null} className= {["smallMarginHorizontal columnContainerWithFlex ",  inIframe () ? "marginToHeaderIframe" :  "marginToHeader"].join(" ")} >
					<h1 className={inIframe() ? "h1FeddbackIframe" : "h1_Feedback"}>{localState.data.FeedbacksTitle}</h1>
					<h3 className= {inIframe ? "paragraphEnd" :"paragraphEnd"}>{localState.data.FeedbacksBody}</h3>
					<div className="scoreContainer">
						<Score img="/img/very-good-icon.png" selected={Selected === "4"} text={t("feedBackVeryGood")} onClick={() => setSelected("4")} />
						<Score img="/img/regular-icon.png" selected={Selected === "3"} text={t("feedBackRegular")} onClick={() => setSelected("3")} />
						<Score img="/img/bad-icon.png" selected={Selected === "1"} text={t("feedBackBad")} onClick={() => setSelected("1")} />
					</div>
					<TextArea placeholder={t("enterComment")} value={Comment} onChange={e => setComment(e.detail.value)} />
					<Button className="alignSelfCenter" disabled={!Selected} size={"large"} onClick={handleSubmit}>
						<FontAwesomeIcon className="arrowIconAdjust" icon={faChevronRight} size={"xs"} />
						{t("continue")}
					</Button>
				</div>
			</PageWrapper>
		) : (
			<div style={{ width: "100%", height: `${window.screen.height}px` }}>
				<Iframe
					className="iframe"
					overflow
					height={`${window.screen.height}px`}
					sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
					url={`https://feedbacks.digital/app/?hash=${localState.data.FeedbacksTopic}&id=${getKey("issueId")}`}
				/>
			</div>
		)
	);
};

export default Feedback;
