import "../pages/Videocall/Opentok/OpentokCall.scss";

export const connectionOptions = ({ videoConfig, Name }) => ({
	credentials: {
		apiKey: videoConfig.AppId,
		sessionId: videoConfig.SessionId,
		token: videoConfig.Token,
	},
	// A container can either be a query selector or an HTML Element
	streamContainers(pubSub, type, data, stream) {
		return {
			publisher: {
				camera: "#cameraPublisherContainer",
				screen: "#screenPublisherContainer",
			},
			subscriber: {
				camera: "#cameraSubscriberContainer",
				screen: "#screenSubscriberContainer",
			},
		}[pubSub][type];
	},
	controlsContainer: "#controls",
	packages: ["annotation", 'textChat'],
	communication: {
		callProperties: null, // Using default
	},
	textChat: {
		name: Name,
		waitingMessage: "Los mensajes serán enviados cuando un operador se conecte",
		container: "#chat",
		alwaysOpen: true
	},
	screenSharing: {
		extensionID: "plocfffmbcclpdifaikiikgplfnepkpo",
		annotation: false,
		externalWindow: false,
		dev: true,
		screenProperties: {
			insertMode: "append",
			width: "100%",
			height: "100%",
			showControls: true,
			style: {
				buttonDisplayMode: "off",
			},
			videoSource: "window",
			fitMode: "contain"
		},
	},
	annotation: {
		absoluteParent: {
			publisher: ".App-video-container",
			subscriber: ".App-video-container"
		}
	},
});
