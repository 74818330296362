import React from "react";
import { IonDatetime } from "@ionic/react";
import "./DateTime.scss";

const DateTime = ({ className, disabled, displayFormat, min, max, onSelected, placeholder, selected }) => {
    
	const DAY_NAMES = [
		"Domingo",
		"Lunes",
		"Martes",
		"Miércoles",
		"Jueves",
		"Viernes",
		"Sábado"
	];

	const MONTH_NAMES = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre"
	];

	return (
		<>
			<IonDatetime
				cancelText="Cerrar"
				className={["applyCustomBoxStyle DateTime", className].join(" ")}
				dayNames={DAY_NAMES}
				disabled={disabled}
				displayFormat={displayFormat}
				doneText="Seleccionar"
				max={max}
				min={min}
				monthNames={MONTH_NAMES}
				placeholder={placeholder}
				value={selected}
				onIonChange={e => onSelected(e.detail.value)}
			/>
		</>
	);
};

export default DateTime;