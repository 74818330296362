import "@ionic/core/css/core.css";
import "@ionic/core/css/ionic.bundle.css";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./App.scss";
import "moment/locale/es";
import * as Sentry from "@sentry/react";
import Routes from "./routes";
import NoPage from "./pages/NoPage";
import Spinner from "./components/Spinner";

import { getLanding } from "./services/api";

moment.locale("es");

const App = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        getLanding(window.location.pathname.split("/")[1])
            .then(res => {
                if (res.success) setData(res.response);
                setLoading(false);
            })
            .catch(() =>  setLoading(false))
    }, [])

    return loading 
        ? <Spinner /> 
        : data 
            ? <Routes data={data} />
            : <NoPage />
};

export default Sentry.withProfiler(App)