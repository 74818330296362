// TODO: Multiple langs
import { setObject, readObject } from "./localStorage";
import { merge } from "lodash";

import TEXTS  from "./translations.json";

const t = (key, defaultText = null) => {
	const texts = merge(TEXTS, readObject("translations") || {})["ES"] || {};
	const Dictionary = readObject("translations")
	if(typeof texts[key] === "undefined") console.log(`Missing translation: ${key}`);
	return Dictionary[key] || texts[key] || defaultText || key; 
};

const registerTranslations = translations => {
	setObject("translations", translations);
};

export default t;
export { registerTranslations };
