import React, { useState, useEffect } from "react";
import { IonImg, IonToast } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { closeCircleOutline } from "ionicons/icons";

import Selector from "../../components/Selector";
import SelectorItem from "../../components/SelectorItem";
import DateTime from "../../components/DateTime";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import PageWrapper from "../../components/PageWrapper";

import t from "../../utils/translations";
import { getKey } from "../../utils/localStorage";
import { setCssColor } from "../../utils/colors";
import { available, createAppoinment } from "../../services/api";
import { createAppointmentQuotaRange } from "./constants";
import { checkForReload } from "../../utils/helpers";
import { landingOnLoad } from "../../utils/landingLoad";

const Appointment = ({ location, history }) => {
	const [loading, setLoading] = useState(true);
	const [localState,] = useState(location.state);
	const [selectedDate, setSelectedDate] = useState(null);
	const [availableTime, setAvailableTime] = useState([]);
	const [selectedTime, setSelectedTime] = useState(null);
	const [appointmentQuota, setAppointmentQuota] = useState(null);
	const [timeError, setTimeError] = useState(null);
	const [issueError, setIssueError] = useState(null);
	const [shouldShowTimeSelector, setShouldShowTimeSelector] = useState(false);

	useEffect(() => {
		landingOnLoad(localState, history, () => setLoading(false), () => setLoading(true))
	}, [localState, history]);

	const handleSetSelectedDate = date => {
		setSelectedTime(null);
		setIssueError(null);
		setSelectedDate(moment(date).format("YYYY-MM-DD"));
		available({
			IdStore: localState.submit.IdStore,
			Date: moment(date).format("YYYY-MM-DD")
		}).then(({ success, response }) => {
			const noTimeAvailable = Object.values(response).every(value => value === false);
			if (success) {
				if (noTimeAvailable) {
					setTimeError(t("noTimeAvailable"));
					setShouldShowTimeSelector(false);
				}
				else if (Object.keys(response).length === 1) {
					setTimeError(null);
					const time = Object.keys(response)[0];
					const formattedTime = moment(time, "HH:mm:ss");
					const range = `${formattedTime.format("HH:mm")} - ${formattedTime.add(localState.config.Block, "minutes").format("HH:mm")}`;
					setSelectedTime(range);
					setAvailableTime(Object.entries(response));
				} else {
					setTimeError(null);
					setShouldShowTimeSelector(true);
					setAvailableTime(Object.entries(response));
				}
			} else {
				setTimeError(response);
				setShouldShowTimeSelector(false);
			}
		});
	};

	const handleSelectedTime = e => {
		setIssueError(null);
		setSelectedTime(e.detail.value);
	};

	const showAppointmentQuota = localState.data.IdIssueType.IdIssueType === 2 && localState.data.AllowAppointmentQuota;

	const createTimeRanges = () => availableTime.map(timeBlock => {
		const time = timeBlock[0];
		const isAvailable = timeBlock[1];
		const formattedTime = moment(time, "HH:mm:ss");
		const range = `${formattedTime.format("HH:mm")} - ${formattedTime.add(localState.config.Block, "minutes").format("HH:mm")}`;
		return <SelectorItem key={range} disabled={!isAvailable} name={range} value={range} />;
	});

	const handleSubmit = event => {
		event && event.preventDefault();
		setLoading(true);
		const { routes, submit } = localState;
		const dateFormatted = moment(selectedDate + " " + selectedTime.substr(0, 5)).format("YYYY-MM-DD HH:mm:ss");
		submit.Date = dateFormatted;
		if (showAppointmentQuota && appointmentQuota) submit.Quota = appointmentQuota;
		const nextRoute = routes.shift();
		const submitData = submit
		submitData.Customer = getKey('Guid')
		createAppoinment(submitData)
			.then(res => {
				if (res.success) {
					history.push({
						pathname: nextRoute,
						state: {
							...localState,
							config: {
								...localState.config,
								guid: res.response.Guid,
								date: selectedDate,
								initialTime: selectedTime.substr(0, 5),
								endTime: selectedTime.substr(8, 14),
								qr: res.response.Qr
							},
							routes
						}
					});
				}
				else {
					setIssueError(res.response);
					setLoading(false);
				}
			})
			.catch(err => {
				console.log(err);
				setLoading(false);
			});
	};

	const createAppointmentQuota = () => {
		const appointmentQuotaResult = localState.data.AllowCompany
			? createAppointmentQuotaRange(30)
			: createAppointmentQuotaRange(6);
		return appointmentQuotaResult.map(elem => <SelectorItem key={elem.key} name={elem.key} value={elem.value} />);
	};

	return loading ? <Spinner /> : (
		<PageWrapper showFooter={localState.data.ShowFooter} showHeader={true} localState={localState}>
			<IonToast
				buttons={[
					{
						side: "start",
						icon: closeCircleOutline,
						handler: () => {
							setTimeError(false);
						}
					}
				]}
				color="danger"
				cssClass="toast-button"
				duration={3000}
				isOpen={!!timeError}
				message={timeError}
				mode="ios"
				onDidDismiss={() => setTimeError(false)}
			/>
			<div className="centerText marginToHeader">
				<IonImg className="smileIcon" src="/img/calendar.png" />
			</div>
			<h2>{t("pleaseSelectDate")}</h2>
			<form className="smallMarginHorizontal columnContainerWithFlex marginToHeader" onSubmit={handleSubmit}>
				<DateTime
					displayFormat={"DDDD D de MMMM de YYYY"}
					max={moment().add(90, "days").format("YYYY-MM-DD")}
					min={moment().format("YYYY-MM-DD")}
					placeholder={t("selectDate")}
					selected={selectedDate}
					onSelected={handleSetSelectedDate}
				/>
				{shouldShowTimeSelector && (
					<Selector
						disabled={!availableTime.length > 0}
						placeholder={t("selectTime")}
						value={selectedTime}
						onChange={e => handleSelectedTime(e)}
					>
						{createTimeRanges()}
					</Selector>
				)}
				{showAppointmentQuota && (
					<Selector
						placeholder={t("selectQuota")}
						value={appointmentQuota}
						onChange={e => setAppointmentQuota(e.detail.value)}
					>
						{createAppointmentQuota()}
					</Selector>
				)}
				<Button className="alignSelfCenter" disabled={!selectedDate || !selectedTime || (showAppointmentQuota && !appointmentQuota)} size={"large"} type="submit">
					<FontAwesomeIcon className="arrowIconAdjust" icon={faChevronRight} size={"xs"} />
					{t("continue")}
				</Button>
				{issueError && (
					<label className="errorText">{issueError}</label>
				)}
			</form>
		</PageWrapper>
	);
};

export default Appointment;
