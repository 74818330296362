import WebRTCAdaptor from "./webrtc_adaptor";
import { getKey, setKey } from "../../../utils/localStorage";
import { isIOS, isMobileAgent, getBrowserInfo, isDev } from "../../../utils/helpers";
import { newNotification, sendStatistics, sendError, sendSignalClient } from "../../../utils/firebase";
import moment from "moment/moment";

export const pcConfig = {
    'iceServers': [{
        'urls': 'stun:stun1.l.google.com:19302'
    }]
};

export const WSurl = "wss://stream04.vivet.app:5443/VivetDev/websocket";

export const sdpConstraints = { OfferToReceiveAudio: false, OfferToReceiveVideo: false };

export const mediaConstraints = { video: true, audio: true };

export const initialAMdata = {
    publishStreamId: null,
    roomOfStream: [],
    streamsList: [],
    isDataChannelOpen: false,
    isCameraOff: false,
};

export const initialShowers = {
    screenPermits: true,
    buttons: true,
    call: false,
    localVideo: false,
    remoteVideo: false,
    localScreen: false,
};

export const initialSources = {
    cameraOn: false,
    micOn: true,
    screenOn: false,
    chatOn: false,
    frontCameraOn: false,
};

var publishMinBitrateValue =0;
var publishAverageBitrateValue =0;
var publishMaxBitrateValue =0;
var publishPacketLostValue = 0;
var publishJitterValue = 0;
var publishRTTValue = 0;

var playMinBitrateValue =0;
var playAverageBitrateValue =0;
var playMaxBitrateValue =0;
var playPacketLostValue = 0;
var playJitterValue = 0;
var playFrameDropValue = 0;

/* WEBRTC CONNECTION */
export const initWebrtc = async (
    webSocketURL,
    onInexistentStream,
    onPlay,
    publishTimeoutError,
    onDataChannelClose,
    onDataReceived,
    onPublish,
    onAlredyPlaying,
    onJoinRoom,
    onStreamInformation,
    onInit,
    onPublishEnd,
    onConnectionEnd,
    onDataChannelOpen,
    checkAndRepublishIfRequired
) => {
    var webrtc = new WebRTCAdaptor({
        websocket_url: webSocketURL || WSurl,
        mediaConstraints: mediaConstraints,
        peerconnection_config: pcConfig,
        sdp_constraints: sdpConstraints,
        localVideoId: "localVideo",
        remoteVideoId: "remoteVideo",
        debug: true,
        bandwidth: 900,
        callback: (info, obj) => successCallback(
            webrtc,
            info,
            obj,
            onInit,
            onPublish,
            onPublishEnd,
            onPlay,
            onConnectionEnd,
            onDataChannelOpen,
            onDataChannelClose,
            onJoinRoom,
            onStreamInformation,
            onDataReceived,
            checkAndRepublishIfRequired
        ),
        callbackError: error => errorCallback(error, webrtc, onAlredyPlaying, onInexistentStream, publishTimeoutError),
    });
    return webrtc;
};

export const initWebrtcMerge = async (
    webSocketURL,
    onInexistentStream,
    onPlay,
    onPublish,
    onDataReceived,
    onAlredyPlaying,
    onJoinRoom,
    onStreamInformation,
    onInit,
    onPublishEnd,
    onConnectionEnd,
    onDataChannelOpen,
    onDataChannelClose,
    checkAndRepublishIfRequired
) => {
    var webrtc = new WebRTCAdaptor({
        websocket_url: webSocketURL || WSurl,
        mediaConstraints: mediaConstraints,
        peerconnection_config: pcConfig,
        sdp_constraints: sdpConstraints,
        localVideoId: "mergeVideo",
        remoteVideoId: "remoteVideo2",
        debug: true,
        bandwidth: 900,
        callback: (info, obj) => successCallback(
            webrtc,
            info,
            obj,
            onInit,
            onPublish,
            onPublishEnd,
            onPlay,
            onConnectionEnd,
            onDataChannelOpen,
            onDataChannelClose,
            onJoinRoom,
            onStreamInformation,
            onDataReceived,
            checkAndRepublishIfRequired

        ),
        callbackError: error => errorCallback(error, webrtc, onAlredyPlaying, onInexistentStream),
    });
    return webrtc;
};

const successCallback = (
    webRtc,
    info,
    obj,
    onInit,
    onPublish,
    onPublishEnd,
    onPlay,
    onConnectionEnd,
    onDataChannelOpen,
    onDataChannelClose,
    onJoinRoom,
    onStreamInformation,
    onDataReceived,
    checkAndRepublishIfRequired
) => {
    if (info === "initialized") onInit && onInit();
    else if (info === "publish_started") onPublish && onPublish();
    else if (info === "joinedTheRoom") onJoinRoom && onJoinRoom(webRtc, obj);
    else if (info === "streamInformation") onStreamInformation && onStreamInformation(webRtc);
    else if (info === "publish_finished") onPublishEnd && onPublishEnd();
    else if (info === "play_started") onPlay && onPlay();
    else if (info === "closed") typeof obj !== "undefined" && onConnectionEnd && onConnectionEnd();
    else if (info === "data_channel_opened") onDataChannelOpen && onDataChannelOpen();
    else if (info == "refreshConnection") checkAndRepublishIfRequired && checkAndRepublishIfRequired()
    else if (info === "data_channel_closed") onDataChannelClose && onDataChannelClose();
    else if (info === "data_received") onDataReceived && onDataReceived(obj);
    else if (info == "updated_stats") showLogsStats(obj)
};

const errorCallback = (error, webrtc, onAlredyPlaying, onInexistentStream, publishTimeoutError) => {
    if (error.indexOf("already_playing") !== -1) onAlredyPlaying && onAlredyPlaying();
    else if (error.indexOf("no_stream_exist") !== -1) onInexistentStream && onInexistentStream(webrtc);
    else if( error.indexOf("publishTimeoutError") !== -1){
        publishTimeoutError && publishTimeoutError()
        sendError(getKey("issueId") , "publishTimeoutError", "video")
    } 
    else if( error.indexOf("NotReadableError") !== -1){
        publishTimeoutError && publishTimeoutError()
        sendError(getKey("issueId") , "NotReadableError", "video")
    } 
    else if( error.indexOf("WebSocketNotConnected") !== -1){
        publishTimeoutError && publishTimeoutError()
        sendError(getKey("issueId") , "WebSocketNotConnected", "video")
    } 
    else if( error.indexOf("data_channel_error") !== -1){
        publishTimeoutError && publishTimeoutError()
        sendError(getKey("issueId") , "data_channel_error", "video")
    } 
    else{
        sendError(getKey("issueId") , JSON.stringify(error), "video")
        console.log("error callback: " + JSON.stringify(error));
    } 
};

/* AUXILIAR FUNCTIONS */
export const turnOffCamera = (webRtc, setter) => {
    webRtc.turnOffLocalCamera();
    newNotification(getKey("issueId"), "client-camera", "off");
    setter(false);
};

export const turnOnCamera = (webRtc, setter) => {
   webRtc.turnOnLocalCamera();
    newNotification(getKey("issueId"), "client-camera", "on");
    setter(true);
};

export const turnOffMic = (webRtc, setter) => {
    webRtc.muteLocalMic();
    setter(false);
};

export const turnOnMic = (webRtc, setter) => {
    webRtc.unmuteLocalMic();
    setter(true);
};

export const turnOnScreen = (webRtc, screenSetter, clientStream, issueGuid) => {
    navigator.mediaDevices.getDisplayMedia().then(res => {
        webRtc.updateVideoTrack(res, clientStream);
        screenSetter(true);
        res.getVideoTracks()[0].onended = () => turnOffScreen(webRtc, screenSetter, clientStream, issueGuid);
        newNotification(issueGuid, "client-screen", "on");
    });
};



export const turnOffScreen = (webRtc, screenSetter, clientStream, issueGuid) => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then(res => {
        webRtc.updateVideoTrack(res, clientStream);
        screenSetter(false);
        newNotification(issueGuid, "client-screen", "off");
    });
};

export const closeConnection = (webRtc, clientStream) => {
    webRtc && webRtc.closeWebSocket(clientStream);
    setKey("reload");
};

export const forceConnection = () => document.getElementById("closeWS").click();

export const publishAndPlay = (webRtc, client, operator, setter) => {
    document.getElementById("publish").addEventListener("click", () => {
        webRtc.publish(client, "mcu");
        webRtc.play(operator);
        setter(true)
    });
    setTimeout(() => {
        document.getElementById("publish").click();
        webRtc.turnOffLocalCamera();
        !isDev && sendStatistics(getKey("issueId"), SO, browser, "[]")
    }, 3000);
};

export const cameraSwapMobile = (webRtc, clientStream, videSourceIndex, setVideoSourceIndex,) => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
        const videoDevices = devices.filter(d => d.kind === "videoinput");
        const audioDeviceDefault = devices.filter(d => d.kind === "audioinput")[0]?.deviceId;
        const cameraBack = videoDevices.find(d => d.label.toLowerCase().includes("back") || d.label.toLowerCase().includes("trás") || d.label.toLowerCase().includes("trasera"));
        if (videSourceIndex) {
           webRtc.switchVideoCameraAndAudioCapture(clientStream, videoDevices[0].deviceId, audioDeviceDefault);
            setVideoSourceIndex(0);
        } else {
             webRtc.switchVideoCameraAndAudioCapture(clientStream, cameraBack.deviceId, audioDeviceDefault);
            setVideoSourceIndex(1);
        }
    }).catch(err => alert("Lo sentimos, no se pudo acceder al dispositivo"));
};
    

export const shareScreenAdjust = (status) => {
    if (isMobileAgent()) {
        const remoteVideo = document.getElementById("remoteVideo");
        if (status === "on") remoteVideo.style = "object-fit: fill;transform: rotate(0deg);box-shadow: 0 0 0 999em rgb(0 0 0 / 100%);background: black;"
        else remoteVideo.style = "transform: rotate(0deg);"
    } else {
        const remoteVideo = document.getElementById("remoteVideo");
        if (status === "on") remoteVideo.style = "object-fit: contain; background: black; position: absolute; transform: rotate(0deg)"
        else remoteVideo.style = "object-fit: cover; background:black; position: absolute;"
    };
};


export const checkPermissions = (onPermissionGranted, onPermissionDenied) => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true })
        .then(() => onPermissionGranted())
        .catch(() => onPermissionDenied())
};

export const getStats = (webRtc, streamId) => webRtc.enableStats(streamId);

export const disableStats = (webRtc, streamId) => webRtc.disableStats(streamId);

const SO = navigator.userAgent.match(/(\(.*\))/).pop().split(")", 1)[0].slice(1)
const browser = getBrowserInfo()

export const showLogsStats = (obj) => {

    const packetLost = parseInt(obj.videoPacketsLost) + parseInt(obj.audioPacketsLost);
    const jitterAverageDelay = ((parseFloat(obj.videoJitterAverageDelay) + parseFloat(obj.audioJitterAverageDelay)) / 2).toPrecision(3);
    playPacketLostValue = packetLost;
    playJitterValue = jitterAverageDelay;
    playFrameDropValue = obj.framesDropped;
    const rtt = ((parseFloat(obj.videoRoundTripTime) + parseFloat(obj.audioRoundTripTime)) / 2).toPrecision(4);
    const data = {
        time: Date.now(),
        AverageBitrate: obj.averageOutgoingBitrate + " kbits/sec",
        MaxBitrate: obj.currentOutgoingBitrate + " kbits/sec",
        RoundTripTime: rtt + " secs",
        videoPacketLost: obj.videoPacketsLost,
        audioPacketsLost: obj.audioPacketsLost,
        videoRTT: obj.videoRoundTripTime,
        audioRTT: obj.audioRoundTripTime,
        videoJitter: obj.videoJitter,
        audioJitter: obj.audioJitter,
    };

    if (publishMinBitrateValue == 0 || publishMinBitrateValue > obj.currentOutgoingBitrate) {
        publishMinBitrateValue = obj.currentOutgoingBitrate;
    }

    if (publishAverageBitrateValue == 0 || publishAverageBitrateValue > obj.averageOutgoingBitrate) {
        publishAverageBitrateValue = obj.averageOutgoingBitrate;
    }

    if (obj.currentOutgoingBitrate != 0 && publishMaxBitrateValue < obj.currentOutgoingBitrate) {
        publishMaxBitrateValue = obj.currentOutgoingBitrate;
    }
    sendSignalClient(getKey("issueId"), { "signal": rtt, "created":moment().valueOf()})
    setKey("RTT", rtt)
     /*  !isDev &&  */sendStatistics(getKey("issueId"), SO, browser, data) 
};