import React from "react";
import Select from "react-select";
import { IonLabel } from "@ionic/react";

const SELECTOR_STYLE = {
	control: (provided, state) => {
		return {
			...provided,
			opacity: state.isDisabled ? 0.4 : 1,
			backgroundColor: state.isDisabled ? "hsl(0,0%,95%)" : "transparent",
			borderColor: "transparent",
			borderWidth: 0,
			height: 44,
			fontSize: "0.8rem",
			textAlign: "center",
		};
	},

	container: (provided) => {
		return {
			...provided,
			padding: 0
		};
	},

	valueContainer: (provided) => {
		return {
			...provided,
			justifyContent: "center"
		};
	},

	indicatorSeparator: (provided) => {
		return {
			...provided,
			backgroundColor: "transparent"
		};
	}
};

const SearchableSelector = ({
	isSearchable,
	className,
	isDisabled,
	options,
	placeholder,
	title,
	value,
	onChange,
}) => (
	<>
		{/* {title &&
            <IonLabel className="title fontColor smallMarginVertical alignSelfCenter">
                {title}
            </IonLabel>
		} */}
		<div>
			<div>
				<Select
					className={["applyCustomBoxStyle", className].join(" ")}
					isDisabled={isDisabled}
					isSearchable={isSearchable}
					options={options}
					placeholder={title}
					styles={SELECTOR_STYLE}
					value={value}
					onChange={onChange}
				/>
			</div>
		</div>
	</>
);

export default SearchableSelector;
